import { Theme } from '@uireact/foundation';

export const DocsTheme: Theme = {
  name: 'UIReactDefaultTheme',
  dark: {
    fonts: {
      token_10: '#ffffff',
      token_50: '#f0f0f0',
      token_100: '#e0dede',
      token_150: '#c9c9c9',
      token_200: '#b3b3b3',
    },
    primary: {
      token_10: '#3B3B3B',
      token_50: '#272727',
      token_100: '#0F0F0F',
      token_150: '#070707',
      token_200: '#000000',
    },
    secondary: {
      token_10: '#3A236A',
      token_50: '#321F59',
      token_100: '#29184C',
      token_150: '#231345',
      token_200: '#180B33',
    },
    tertiary: {
      token_10: '#61C9FC',
      token_50: '#45C0FB',
      token_100: '#1FB6FF',
      token_150: '#18A9EF',
      token_200: '#129CDF',
    },
    positive: {
      token_10: '#67b879',
      token_50: '#49a35d',
      token_100: '#368f4a',
      token_150: '#278c3e',
      token_200: '#186e2c',
    },
    negative: {
      token_10: '#a6a4a4',
      token_50: '#827f7f',
      token_100: '#636161',
      token_150: '#424141',
      token_200: '#1c1b1b',
    },
    error: {
      token_10: '#c21f1f',
      token_50: '#a82020',
      token_100: '#911919',
      token_150: '#6b1212',
      token_200: '#470b0b',
    },
    warning: {
      token_10: '#e0d902',
      token_50: '#b5af02',
      token_100: '#948f01',
      token_150: '#757105',
      token_200: '#575406',
    },
  },
  light: {
    fonts: {
      token_10: '#6e6e6e',
      token_50: '#444544',
      token_100: '#282928',
      token_150: '#262626',
      token_200: '#0a0a0a',
    },
    primary: {
      token_10: '#FFFFFF',
      token_50: '#FAFAFA',
      token_100: '#F6F5F5',
      token_150: '#DEDDDD',
      token_200: '#D3D2D2',
    },
    secondary: {
      token_10: '#65C7F6',
      token_50: '#59BEEF',
      token_100: '#44B0E4',
      token_150: '#3BADE5',
      token_200: '#2F9ED5',
    },
    tertiary: {
      token_10: '#1E8CC1',
      token_50: '#1A85B8',
      token_100: '#187FB0',
      token_150: '#1379A9',
      token_200: '#116F9C',
    },
    positive: {
      token_10: '#62fc84',
      token_50: '#45DC66',
      token_100: '#30BF4F',
      token_150: '#2CAF49',
      token_200: '#1c8032',
    },
    negative: {
      token_10: '#e3e3e3',
      token_50: '#B5B4B4',
      token_100: '#9B9A9A',
      token_150: '#7C7C7C',
      token_200: '#595858',
    },
    error: {
      token_10: '#f7a3a3',
      token_50: '#FF8181',
      token_100: '#FD6A6A',
      token_150: '#FF5A5A',
      token_200: '#de3737',
    },
    warning: {
      token_10: '#fcf651',
      token_50: '#DED802',
      token_100: '#C8C204',
      token_150: '#BFB903',
      token_200: '#8a8601',
    },
  },
  sizes: {
    headings: {
      level1: '26px',
      level2: '24px',
      level3: '20px',
      level4: '18px',
      level5: '18px',
      level6: '18px',
    },
    texts: {
      xsmall: '12px',
      small: '14px',
      regular: '16px',
      large: '20px',
      xlarge: '22px',
    },
  },
  texts: {
    font: "Sen, 'Source Sans Pro', sans- serif",
  },
  spacing: {
    one: '1px',
    two: '2px',
    three: '4px',
    four: '8px',
    five: '16px',
    six: '32px',
    seven: '64px',
  },
};
